import { roundXToNDecimal } from '@frk/helpers';

import {
  SelectionKeySkillsLevel,
  MissionPriorityEnum,
  MissionDuration,
  OpportunityType,
  ExpectedContract,
  MissionStatusEnum,
  SelectionStatus,
} from '@frk/graphql-types';
import { SELECTION_KEY_SKILLS_LEVEL_OPTIONS } from './KeySkill.core';
import { ConstantOption } from './utils.core';

// ? Experimental : use the label as value
export const PROFILE_LEVEL_OPTIONS = SELECTION_KEY_SKILLS_LEVEL_OPTIONS.filter(
  level => level.value !== SelectionKeySkillsLevel.Level_0
).map(level => ({
  ...level,
  value: level.label,
}));

export const MISSION_DURATION_OPTIONS: ConstantOption<MissionDuration>[] = [
  { value: MissionDuration.None, label: 'Non fixée' },
  { value: MissionDuration.LessThanThreeMonths, label: '- de 3 mois' },
  { value: MissionDuration.BetweenThreeAndSixMonths, label: '3 à 6 mois' },
  { value: MissionDuration.BetweenSixAndTwelveMonths, label: '6 à 12 mois' },
  { value: MissionDuration.MoreThanTwelveMonths, label: '+ de 12 mois' },
];

export const MISSION_PRIORITY_OPTIONS: ConstantOption<MissionPriorityEnum>[] = [
  { value: MissionPriorityEnum.Priority_0, label: 'Priorité 0', default: true },
  { value: MissionPriorityEnum.Priority_1, label: 'Priorité 1' },
  { value: MissionPriorityEnum.Priority_2, label: 'Priorité 2' },
  { value: MissionPriorityEnum.Priority_3, label: 'Priorité 3' },
];

// Marge cible de 18% pour les missions
export const FRK_MARGIN = 0.18;

export function getFreelancePriceFromClientPrice(price: number): number {
  return roundXToNDecimal(price * (1 - FRK_MARGIN), 2);
}

// We want nice rounded numbers multiple of 5 for the client
export function getClientPriceFromFreelancePrice(price: number): number {
  return roundXToNDecimal(price / (1 - FRK_MARGIN), 2);
}

export const MISSION_TYPE_OPTIONS: ConstantOption<OpportunityType>[] = [
  { value: OpportunityType.Cdi, label: 'CDI' },
  { value: OpportunityType.Freelancing, label: 'Freelancing' },
];

export function areFreelanceExpectedContractAndOpportunityTypeCompatible(
  freelanceExpectedContract: ExpectedContract,
  opportunityType: OpportunityType
): boolean {
  if (opportunityType === OpportunityType.Cdi) {
    return [ExpectedContract.Cdi, ExpectedContract.FreelanceOrCdi, ExpectedContract.Nc].includes(
      freelanceExpectedContract
    );
  }
  return [ExpectedContract.Freelance, ExpectedContract.FreelanceOrCdi, ExpectedContract.Nc].includes(
    freelanceExpectedContract
  );
}

export const SEARCH_KANBAN_STATUS_TUPLES = [
  [MissionStatusEnum.SearchActive, SelectionStatus.Selected, 'Sendouts', 'Pas encore de sendout'],
  [MissionStatusEnum.PropositionActive, SelectionStatus.PendingClient, 'Propositions', 'Pas de proposition'],
  [MissionStatusEnum.InterviewActive, SelectionStatus.Interview, 'Entretien', "Pas d'entretien"],
  [MissionStatusEnum.DealBefore, SelectionStatus.Ok, 'Runner', 'Pas de runner ?!'],
  [MissionStatusEnum.DealOngoing, SelectionStatus.Ok, 'Runner', 'Pas de runner ?!'],
];

// ? Voir les commentaires https://www.notion.so/Ta-en-bas-de-la-liste-des-recommandations-je-peux-cliquer-sur-un-bouton-j-en-veux-plus-3-fois-a-1b45ebd0ed57489c81d822d02a1504ec?d=ddd390bd463e47b18dc924b5fe6eb08d
export const SELECTIVE_BUSINESS_MAX_LIMIT = 100;

export const OPPORTUNITY_SHARE_DESTINATIONS = [
  { label: 'Slack', value: 'slack', checked: true },
  { label: 'TurnOver IT', value: 'turnover', checked: false },
];
