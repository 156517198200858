"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'FrkHelpIcon',
    props: {
        trigger: {
            type: String,
            default: 'click',
        },
    },
    setup(__props) {
        return { __sfc: true, };
    }
});
