"use strict";
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUserNotifiedSubscription = exports.UserNotifiedDocument = void 0;
const graphql_vue_apollo_patch_1 = require("@frk/graphql-vue-apollo-patch");
const vue_apollo_smart_ops_1 = require("vue-apollo-smart-ops");
exports.UserNotifiedDocument = (0, graphql_vue_apollo_patch_1.gql) `
    subscription userNotified {
  userNotified {
    id
    status
    message
  }
}
    `;
/**
 * __useUserNotifiedSubscription__
 *
 * To use a Smart Subscription within a Vue component, call `useUserNotifiedSubscription` as the value for a `$subscribe` key
 * in the component's `apollo` config, passing any options required for the subscription.
 *
 * @param options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.subscribe
 *
 * @example
 * {
 *   apollo: {
 *     $subscribe: {
 *       userNotified: useUserNotifiedSubscription({
 *         variables: {},
 *         loadingKey: 'loading',
 *         fetchPolicy: 'no-cache',
 *       }),
 *     },
 *   }
 * }
 */
exports.useUserNotifiedSubscription = (0, vue_apollo_smart_ops_1.createSmartSubscriptionOptionsFunction)(exports.UserNotifiedDocument);
