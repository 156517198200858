import Vue from 'vue';

import { hasAcceptedLatestTerms } from '@frk/commons/api/models/TermsList.core';
import { getInstance } from '@frk/commons/ui/plugins/auth';
import VueRouter, { NavigationGuardNext, Route, isAuthenticated, watchAuthLoading } from '@frk/commons/ui/router';

const components = {
  AccountNotFoundPage: () => import('./ui/pages/AccountNotFoundPage.vue'),
  AuthCallbackPage: () => import('./ui/pages/AuthCallbackPage.vue'),
  CallToActionPage: () => import('./ui/pages/CallToActionPage.vue'),
  ClientOpportunitiesPage: () => import('./ui/pages/ClientOpportunitiesPage.vue'),
  ClientOpportunityPage: () => import('./ui/pages/ClientOpportunityPage.vue'),
  CompanyPage: () => import('./ui/pages/CompanyPage.vue'),
  CandidateMarketSituationPage: () => import('./ui/pages/CandidateMarketSituationPage.vue'),
  CandidateOpportunitiesPage: () => import('./ui/pages/CandidateOpportunitiesPage.vue'),
  CandidatePreferencesPage: () => import('./ui/pages/CandidatePreferencesPage.vue'),
  FirstUseClientPage: () => import('./ui/pages/FirstUseClientPage.vue'),
  FirstUseCandidatePage: () => import('./ui/pages/FirstUseCandidatePage.vue'),
  CandidatePublicProfilePage: () => import('./ui/pages/CandidatePublicProfilePage.vue'),
  CandidateReferencesPage: () => import('./ui/pages/CandidateReferencesPage.vue'),
  CandidateResumePage: () => import('./ui/pages/CandidateResumePage.vue'),
  InvitationPage: () => import('./ui/pages/InvitationPage.vue'),
  SignupPage: () => import('./ui/pages/SignupPage.vue'),
  ManagerRecommendationPage: () => import('./ui/pages/ManagerRecommendationPage.vue'),
  ProfilePage: () => import('./ui/pages/ProfilePage.vue'),
  ProposalKeySkillsActionPage: () => import('./ui/pages/ProposalKeySkillsActionPage.vue'),
  UserDashboardProxyPage: () => import('./ui/pages/UserDashboardProxyPage.vue'),
  UserOpportunitiesProxyPage: () => import('./ui/pages/UserOpportunitiesProxyPage.vue'),
};

export const guards = {
  isAuthenticated,
  isAnonymous(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    watchAuthLoading(next, $auth => {
      if (!$auth.loggedUser) {
        return next();
      }

      next({ name: 'dashboard' });
    });
  },
};

export const routes = [
  {
    path: '/no-account',
    name: 'accountNotFound',
    component: components.AccountNotFoundPage,
  },
  // ? Two routes for the same component
  // ? Simplify mixpanel tracking & customer io copywriting
  {
    path: '/freelance-market-situation-edit',
    name: 'CandidateMarketSituationEdit',
    component: components.CallToActionPage,
  },
  {
    path: '/mission-selection-freelance-answer',
    name: 'MissionSelectionFreelanceAnswer',
    component: components.CallToActionPage,
  },
  {
    path: '/proposal-key-skills/:proposalId',
    name: 'KeySkillsPage',
    component: components.ProposalKeySkillsActionPage,
    beforeEnter: guards.isAuthenticated,
  },
  // ! Deprecated route kept for legacy reasons for now
  {
    path: '/welcome/freelance/description',
    name: 'suFreelance',
    component: components.SignupPage,
    beforeEnter: guards.isAuthenticated,
  },
  {
    path: '/welcome/freelance',
    name: 'fuFreelance',
    component: components.FirstUseCandidatePage,
    beforeEnter: guards.isAuthenticated,
  },
  // ! Deprecated route kept for legacy reasons for now
  {
    path: '/welcome/company/description',
    name: 'suClient',
    component: components.SignupPage,
    beforeEnter: guards.isAuthenticated,
  },
  {
    path: '/welcome/client',
    name: 'fuClient',
    component: components.FirstUseClientPage,
    beforeEnter: guards.isAuthenticated,
  },
  {
    path: '/invitation/:token',
    name: 'invitation',
    component: components.InvitationPage,
    props: true,
  },
  {
    path: '/signup/freelance',
    name: 'signupFreelance',
    component: components.SignupPage,
  },
  {
    path: '/signup/client',
    name: 'signupClient',
    component: components.SignupPage,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    alias: ['/'],
    component: components.UserDashboardProxyPage,
    beforeEnter: guards.isAuthenticated,
  },
  {
    path: '/cv',
    name: 'freelanceCV',
    component: components.CandidateResumePage,
    beforeEnter: guards.isAuthenticated,
  },
  {
    path: '/profile',
    name: 'profile',
    component: components.ProfilePage,
    beforeEnter: guards.isAuthenticated,
  },
  {
    path: '/situation',
    name: 'situation',
    component: components.CandidateMarketSituationPage,
    beforeEnter: guards.isAuthenticated,
  },
  {
    path: '/references',
    name: 'references',
    component: components.CandidateReferencesPage,
    beforeEnter: guards.isAuthenticated,
  },
  {
    path: '/missions',
    name: 'missions',
    component: components.UserOpportunitiesProxyPage,
    beforeEnter: guards.isAuthenticated,
  },
  {
    path: '/company',
    name: 'company',
    component: components.CompanyPage,
    beforeEnter: guards.isAuthenticated,
  },
  {
    path: '/preferences',
    name: 'missionPreferences',
    component: components.CandidatePreferencesPage,
    beforeEnter: guards.isAuthenticated,
  },
  {
    path: '/references',
    name: 'references',
    component: components.CandidateReferencesPage,
    beforeEnter: guards.isAuthenticated,
  },
  {
    path: '/missions',
    name: 'missions',
    component: components.UserOpportunitiesProxyPage,
    beforeEnter: guards.isAuthenticated,
  },
  {
    path: '/company',
    name: 'company',
    component: components.CompanyPage,
    beforeEnter: guards.isAuthenticated,
  },
  {
    path: '/preferences',
    name: 'missionPreferences',
    component: components.CandidatePreferencesPage,
    beforeEnter: guards.isAuthenticated,
  },
  {
    path: '/freelance-review/:missionId/:freelanceId',
    name: 'freelancePublicProfile',
    component: components.CandidatePublicProfilePage,
    props: true,
  },
  {
    path: '/manager-recommendation/:freelanceId/:recommendationId',
    name: 'managerRecommendationPage',
    component: components.ManagerRecommendationPage,
    props: true,
  },
  {
    path: '/mission/:missionId',
    name: 'opportunityDetails',
    component: components.ClientOpportunityPage,
    props: true,
    beforeEnter: guards.isAuthenticated,
  },
  {
    path: '/loading',
    name: 'loginCallback',
    component: components.AuthCallbackPage,
  },
  {
    path: '*',
    redirect: '/',
  },
];

const createRouter = ({ sentry }) => {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) return savedPosition;
      return { x: 0, y: 0 };
    },
  });

  router.beforeEach(async (to, from, next) => {
    Vue.$modal.hide();

    next();
  });

  router.afterEach(async to => {
    try {
      const $auth = getInstance();
      const loggedUser = $auth.loggedUser;

      Vue.$gtm.trackPageView(to.fullPath, document.title, loggedUser !== null);
      if (loggedUser) {
        if (process.env.VUE_APP_PAGEVIEWS_FUNCTION) {
          const searchParams = new URLSearchParams({
            userId: loggedUser._id,
            page: window.location.href,
          });
          await fetch(`${process.env.VUE_APP_PAGEVIEWS_FUNCTION}?${searchParams}`);
        }

        const termsList = loggedUser.termsList;

        if (!hasAcceptedLatestTerms(termsList)) {
          Vue.$modal.show('userTerms');
        }
      }
    } catch (err) {
      sentry.captureException(err);
    }

    document.dispatchEvent(new Event('navigation-complete'));
  });

  // ! This code is an attempt to prevent "Navigation aborted" errors from a redirection to flood Sentry
  // ? I don't find a way to replicate the error in local even with a production build
  router.onError(err => {
    const { isNavigationFailure, NavigationFailureType } = VueRouter;
    if (!isNavigationFailure(err, NavigationFailureType.redirected)) {
      sentry.captureException(err);
    }
  });

  return router;
};

export { createRouter };
