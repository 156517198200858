"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GTM_LEGACY_VAR_COMPANY_TOTAL_MISSION = exports.GTM_LEGACY_VAR_COMPANY_STATUS = exports.GTM_LEGACY_VAR_FREELANCE_JOB = exports.GTM_LEGACY_VAR_FREELANCE_PRICE = exports.GTM_LEGACY_VAR_VISITOR_PROFILE_COMPLETION_RATE = exports.GTM_LEGACY_VAR_VISITOR_LOCATION = exports.GTM_LEGACY_VAR_VISITOR_LOGGING_STATE_UNLOGGED = exports.GTM_LEGACY_VAR_VISITOR_LOGGING_STATE_LOGGED = exports.GTM_LEGACY_VAR_VISITOR_LOGGING_STATE = exports.GTM_LEGACY_VAR_VISITOR_TYPE_CLIENT = exports.GTM_LEGACY_VAR_VISITOR_TYPE_FREELANCE = exports.GTM_LEGACY_VAR_VISITOR_TYPE = exports.GTM_LEGACY_VAR_VISITOR_ID = void 0;
// TODO GTM Legacy: is this thing useful for the business ?? can we delete this ?
/**
 * The user ObjectId when logged or undefined.
 * @type {string}
 */
exports.GTM_LEGACY_VAR_VISITOR_ID = 'visitorId';
/**
 * 'freelance' | 'company'. The user role.
 * @type {string}
 */
exports.GTM_LEGACY_VAR_VISITOR_TYPE = 'visitorType';
exports.GTM_LEGACY_VAR_VISITOR_TYPE_FREELANCE = 'freelance';
exports.GTM_LEGACY_VAR_VISITOR_TYPE_CLIENT = 'company';
/**
 * Is the user logged?
 * @type {string}
 */
exports.GTM_LEGACY_VAR_VISITOR_LOGGING_STATE = 'visitorLoggingState';
exports.GTM_LEGACY_VAR_VISITOR_LOGGING_STATE_LOGGED = 'logged';
exports.GTM_LEGACY_VAR_VISITOR_LOGGING_STATE_UNLOGGED = 'unlogged';
/**
 * The city of the User.
 * @type {string}
 */
exports.GTM_LEGACY_VAR_VISITOR_LOCATION = 'visitorLocation';
/**
 * The User profile completion rate (Number between 0 and 100).
 * @type {string}
 */
exports.GTM_LEGACY_VAR_VISITOR_PROFILE_COMPLETION_RATE = 'visitorProfileCompletionRate';
/**
 * Freelance price.
 * @type {string}
 */
exports.GTM_LEGACY_VAR_FREELANCE_PRICE = 'freelancePrice';
/**
 * Freelance title.
 * @type {string}
 */
exports.GTM_LEGACY_VAR_FREELANCE_JOB = 'freelanceJob';
/**
 * 'companyRepeater' | 'companyFirstTime' : Has the Client submitted several missions?
 * @type {string}
 */
exports.GTM_LEGACY_VAR_COMPANY_STATUS = 'companyStatus';
/**
 * Number of missions submitted by the Client.
 * @type {string}
 */
exports.GTM_LEGACY_VAR_COMPANY_TOTAL_MISSION = 'companyTotalMission';
