import {
  Activity,
  CategorizedJobEnum,
  ExpectedContract,
  FreelanceImportSourceEnum,
  FreelanceStatus,
  Timing,
} from '@frk/graphql-types';
import { UserTraitList } from './User.core';
import { ColoredConstantOption, ConstantOption } from './utils.core';

export const FreelanceTraitList = [...UserTraitList, 'source', 'status', 'jobs', 'expectedContract'] as const;
export type FreelanceTrait = (typeof FreelanceTraitList)[number];

export const SOURCE_OPTIONS: ConstantOption<FreelanceImportSourceEnum>[] = [
  { value: FreelanceImportSourceEnum.Apec, label: 'APEC' },
  { value: FreelanceImportSourceEnum.ClientReferral, label: 'Recommandation client' },
  { value: FreelanceImportSourceEnum.FreelanceReferral, label: 'Recommandation freelance' },
  { value: FreelanceImportSourceEnum.Ib, label: 'IB' },
  { value: FreelanceImportSourceEnum.JeanMichel, label: 'Jean-Michel' },
  { value: FreelanceImportSourceEnum.Freework, label: 'Freework' },
  { value: FreelanceImportSourceEnum.Linkedin, label: 'Linkedin' },
  { value: FreelanceImportSourceEnum.Malt, label: 'Malt' },
  { value: FreelanceImportSourceEnum.Organic, label: 'Organique' },
  { value: FreelanceImportSourceEnum.Other, label: 'Autre' },
  { value: FreelanceImportSourceEnum.PoleEmploi, label: 'Pôle Emploi' },
];

export const EXPECTED_CONTRACT_OPTIONS: ConstantOption<ExpectedContract>[] = [
  { value: ExpectedContract.Cdi, label: 'CDI' },
  { value: ExpectedContract.Freelance, label: 'Freelance' },
  { value: ExpectedContract.FreelanceOrCdi, label: 'Freelance ou CDI' },
  { value: ExpectedContract.Nc, label: 'Non communiqué' },
];

export const EXPECTED_CONTRACT_DISPLAY_OPTIONS: ConstantOption<ExpectedContract>[] = [
  { value: ExpectedContract.Cdi, label: 'CDI' },
  { value: ExpectedContract.Freelance, label: 'Free' },
  { value: ExpectedContract.FreelanceOrCdi, label: 'F/C' },
  { value: ExpectedContract.Nc, label: 'NC' },
];

export const FREELANCE_STATUS_OPTIONS: ColoredConstantOption<FreelanceStatus>[] = [
  { value: FreelanceStatus.Prospect, label: 'Prospect', color: 'black' },
  { value: FreelanceStatus.Pending, label: 'Profil incomplet', color: 'red' },
  { value: FreelanceStatus.Stashed, label: 'Qualif non prioritaire', color: 'stone' },
  { value: FreelanceStatus.Intest, label: 'Qualif prioritaire', color: 'blue' },
  { value: FreelanceStatus.Candidate, label: 'A qualifier', color: 'orange' },
  { value: FreelanceStatus.Ko, label: 'Refusé', color: 'red' },
  { value: FreelanceStatus.Ok, label: 'Membre FRK', color: 'green' },
  { value: FreelanceStatus.Runner, label: 'Runner', color: 'green' },
  { value: FreelanceStatus.Former, label: 'Former', color: 'green' },
];

export const ACTIVITY_OPTIONS: ConstantOption<Activity>[] = [
  { value: Activity.Freelance, label: 'Freelance' },
  { value: Activity.Salary, label: 'Salarié' },
  { value: Activity.Student, label: 'Étudiant' },
];

export const TIMING_OPTIONS: ConstantOption<Timing>[] = [
  { value: Timing.Full, label: 'Temps plein' },
  { value: Timing.Partial, label: 'Temps partiel' },
  { value: Timing.Partial_3, label: 'Temps partiel 3/5' },
  { value: Timing.Partial_4, label: 'Temps partiel 4/5' },
  { value: Timing.Both, label: 'Temps plein ou partiel' },
];

// ? https://developer.textkernel.com/ExtractApi/master/data_model/dm1/#degree-type-french
export type TextKernelHighestDegreeV1 =
  | ''
  | 'BAC'
  | 'BAC2'
  | 'BAC3'
  | 'BAC4'
  | 'BAC5'
  | 'BAC8'
  | 'BACPRO'
  | 'BEPCAP'
  | 'ELEMENTARY';

// ? https://developer.textkernel.com/ExtractApi/master/data_model/dm2/#degree-type-international
export type TextKernelHighestDegreeV2 = '' | '0' | '1' | '2' | '3' | '4' | '5';

export type TextKernelHighestDegree = TextKernelHighestDegreeV1 | TextKernelHighestDegreeV2;

export const HIGHEST_DEGREE_LEVEL_MAPPING = {
  INCONNU: -1,
  PRE_BAC: 0,
  BAC: 1,
  BAC_PRO: 2,
  BAC_3: 3,
  BAC_5: 4,
  BAC_8: 5,
} as const;
type HighestDegreeLevelKeys = keyof typeof HIGHEST_DEGREE_LEVEL_MAPPING;
export type HighestDegreeLevel = (typeof HIGHEST_DEGREE_LEVEL_MAPPING)[HighestDegreeLevelKeys];

// ! These options are meant to be used only for Algolia - see solution and associated tradeoffs
// ! Values are casted by Algolia to string -> map option value transform from number to string here
// ? It has been estimated too expensive to implement custom mapping at the refinement list level
// ! Attention, we use numeric values in DB but string values in Algolia...
export const HIGHEST_DEGREE_LEVEL_OPTIONS: ConstantOption[] = [
  { value: HIGHEST_DEGREE_LEVEL_MAPPING.INCONNU, label: 'Inconnu', default: true },
  { value: HIGHEST_DEGREE_LEVEL_MAPPING.PRE_BAC, label: 'Pre-bac' },
  { value: HIGHEST_DEGREE_LEVEL_MAPPING.BAC, label: 'Bac' },
  { value: HIGHEST_DEGREE_LEVEL_MAPPING.BAC_PRO, label: 'Bac pro' },
  { value: HIGHEST_DEGREE_LEVEL_MAPPING.BAC_3, label: 'Bac+3' },
  { value: HIGHEST_DEGREE_LEVEL_MAPPING.BAC_5, label: 'Bac+5' },
  { value: HIGHEST_DEGREE_LEVEL_MAPPING.BAC_8, label: 'Bac+8' },
].map(option => ({ ...option, value: option.value.toString() }));

export const humanizedCategorizedJobsTable = Object.values(CategorizedJobEnum).reduce((table, value) => {
  const categoryAndLabel = value.split('___');

  const [category, label] = categoryAndLabel
    .map(encodedText => encodedText.split('__').join(' / ').split('_').join(' '))
    .map(text => {
      // ? Hack to avoid including reordering in the enum
      if (text === 'Xyz') {
        text = 'Autre';
      }

      return text;
    });

  return {
    ...table,
    [category]: [...(table[category] || []), { label, value }],
  };
}, {} as { [category: string]: ConstantOption<CategorizedJobEnum>[] });

export const JOBS_OPTIONS = Object.values(humanizedCategorizedJobsTable).reduce((list, jobs) => [...list, ...jobs], []);

export function isTalentAdvocateReachable(status: FreelanceStatus): boolean {
  return [FreelanceStatus.Ok, FreelanceStatus.Former, FreelanceStatus.Runner, FreelanceStatus.Intest].includes(status);
}
